<h4>استقبال التلاميذ </h4>
<div class="card">
  <div class="card-body">
    <p>معايير البحث</p><hr>
    <div class="row" [hidden]="hidepage1">
      <div class="col-4">
        <label for="myselect">الأكاديمية : </label>
        <select class="form-control"  id='academies' (change)="settFiltreActive($event.target)" >
          <option *ngFor="let academie of myacademies"  >{{academie}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="myselect">م.الاقليمية : </label>
        <select class="form-control" id='directions' (change)="settFiltreActive($event.target)" >
          <option></option>
          <option *ngFor="let direction of mydirections"  >{{direction}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="myselect">الجماعة : </label>
        <select class="form-control" id='communes' (change)="settFiltreActive($event.target)" >
          <option></option>
          <option *ngFor="let commune of mycommunes"  >{{commune}}</option>
        </select>
      </div>


      <div class="col-4">
        <label for="myselect">المؤسسة : </label>
        <select class="form-control"  id='ecoles' (change)="settFiltreActive($event.target)" >
          <option></option>
          <option *ngFor="let ecole of myecoles"  >{{ecole}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="myselect">السلك : </label>
        <select class="form-control" id='cycles' (change)="settFiltreActive($event.target)" >
          <option *ngFor="let cycle of cycles"  >{{cycle}}</option>
        </select>
      </div>
      <div class="col-4">
        <label for="myselect">رقم التلميذ : </label>
        <input type="text" class="form-control" id='nmassar' #nmassar  >
      </div>

    <div class="mybtn col-12">
      <button  class="btn-primary" style="float: left;height: 35px;margin-top: 28px;"
      (click)="find(nmassar.value)"> بحث <i class="fa fa-search" ></i></button>
    </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body" >
<app-mytab [myarray]="arraytab"  (indexTab)="indexTab($event)" ></app-mytab>
<div class="row" [hidden] = "!hidepage1">
  <br>
  <div style="margin-top:15px; margin-right:25px;font-weight:bold"> {{ 'المؤسسة الاصلية :' + ecole + '(' + gresa + ')' }}</div>
  <div class="col-12"></div>
  <div class="col-4">
    <label for="myselect">نوع التعليم : </label>
    <select class="form-control"  id='types' (change)="settFiltreActive($event.target)" >
      <option *ngFor="let item of types"  >{{item}}</option>
    </select>
  </div>
  <div class="col-4">
    <label for="myselect">السلك : </label>
    <select class="form-control" id='cycles' (change)="settFiltreActive($event.target)" >
      <option *ngFor="let item of cycles"  >{{item}}</option>
    </select>
  </div>
  <div class="col-12"></div>
  <br>
  <div class="col-4">
    <label for="myselect">المستوى : </label>
    <select class="form-control" id="niveau" #niveau  >
      <option *ngFor="let n of listniveaux"  >{{n.niveau}}</option>
    </select>
  </div>
  <div class="mybtn col-8">
    <button  class="btn-primary" style="float: left;height: 35px;margin-top: 28px;"
    (click)="findclasse(niveau.value)"> بحث <i class="fa fa-search" ></i></button>
  </div>
</div>
<hr><br>
  <div [hidden]="hidepage1">
<app-smarttable [data]="dataview" (addlinkclicked)="addlinkclicked($event)"
[newKeysnames]="{
  gresa: 'الرمز',
  ecole: 'المؤسسة',
  direction :'م.الاقليمية',
  commune : 'الجماعة',
  nbrpartants:'عدد المغادرين',
  ddepart: 'تاريخ اخر تحويل',
  addlink: {name: 'عرض التحويلات', path: ''}
  }"
[maxview]="20"></app-smarttable>
  </div>
  <div [hidden]="!hidepage1">
    <app-smarttable [data]="datalistElevesActive"
    [newKeysnames]="{
      addcheck: 'addcheck',
      nmassar: 'رقم التلميذ',
      arprenom: 'الاسم',
      arnom :'النسب',
      dnaissance : 'تاريخ الازدياد',
      genre:'الجنس',
      niveau: 'المستوى',
      ddepart: 'تاريخ التحويل',
      typetransfert:'نوع التحويل'
      }"
    [maxview]="20"></app-smarttable>
    <hr>
    <div class="row" [hidden]="hidebaspage2">
      <div class="col-3">
        <label for="myselect">القسم : </label>
        <select class="form-control" id='classe' #class >
          <option value="*">*</option>
          <option *ngFor="let c of listnumerosclasses" >{{c.numero}}</option>
        </select>
      </div>
      <div class="col-3 ">
        <label for="myselect">تاريخ الاستقبال : </label>
        <app-mydatepicker  [data] = "mydatereception" (dateChange)="mydatereception= $event"
        [myclass]="'form-control'"></app-mydatepicker>
      </div>
      <div class="mybtn col-3">
        <button  class="btn-primary" style="float:right; height: 35px;margin-top: 38px;"
        (click)="reception(class.value)"> إستقبال <i class="fa fa-save" ></i></button>
      </div>
    </div>


      </div>
  </div>
</div>
