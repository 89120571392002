
  <div class="card text-white mb-3 mr-3" style="max-width: 19rem;border:1px solid transparent" [ngStyle]="{'background-color':databox.bg }">
    <div class="card-body">
      <i class="fa fa-5x myicon" [ngClass]="databox.icon"></i>
      <h3 class="card-title">{{databox.valeur}}</h3>
      <p class="card-text">{{databox.title}}</p>
    </div>
    <div class="card-footer text-center" (click)="openDetails(databox.path)"
    style="color:rgba(255,255,255,0.8);font-size:13px;">
      التفاصيل <i class="fa fa-arrow-circle-left"></i></div>
  </div>
