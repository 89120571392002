
<h4>لائحة تلاميذ  </h4>
<app-choixclasses [choixSelect]="4" (save)="showfilterclass($event)"></app-choixclasses>

<!-- ICI TABS-->
<div [hidden]="showtable">
  <div class="card">
    <div class="card-body" >
<app-mytab [myarray]="arraytab"  (indexTab)="indexTab($event)" ></app-mytab>

<button [hidden]="index != 1"  class="btn-primary" (click)="correct()"
style="padding:5px; margin:5px;" > تصحيح وضعية المكررين <i class="fa fa-edit" ></i></button>
<button  class="btn-primary" (click)="delete()"
style="padding:5px; margin:5px;" > حذف <i class="far fa-trash-alt"></i></button>
<div class="clearfix"></div>

<app-smarttable [data]="dataeleves"
[newKeysnames]="{
  addcheck:'addcheck',
  nmassar: 'رقم التلميذ',
  nom: 'النسب بالفرنسية',
  prenom :'الاسم بالفرنسية',
  arnom : 'النسب بالعربية',
  arprenom:'الاسم بالعربية',
  genre: 'النوع',
  dnaissance: 'تاريخ الازدياد'
  }"
[maxview]="20"></app-smarttable>
    </div>
  </div>
</div>
