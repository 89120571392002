<p>listaccueil works!</p>
<h4>لائحة التحويلات  </h4>
<app-choixclasses [choixSelect]="7" (save)="showfilterclass($event)"></app-choixclasses>

<!-- ICI TABS-->
<div [hidden]="showtable">
  <div class="card">
    <div class="card-body" >


<app-smarttable [data]="dataeleves"
[newKeysnames]="{
  addcheck:'addcheck',
  nmassar: 'رقم التلميذ',
  addconcat: {name: 'الاسم الكامل', concat: ['arnom', 'arprenom']},
  dnaissance: 'تاريخ الازدياد',
  genre : 'الجنس',
  myniveau : 'المستوى',
  ecoleorigine : 'المؤسسة الاصلية',
  ddepart: 'تاريخ التحويل',
  typetransfert: 'نوع التحويل',
  dreception : 'تاريخ الاستقبال'

  }"
[maxview]="20"></app-smarttable>
    </div>
  </div>
</div>
