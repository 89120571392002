<p>correctposition works!</p>

<div class="card">
  <div class="card-body" >
<app-smarttable [data]="dataeleves" (trSelected)="trSelected($event)" [activeColorTr]="true"
[newKeysnames]="titles"
[maxview]="20"></app-smarttable>
  </div>
</div>
<div class="card">
  <div class="card-body" >
    <div class="row">
      <div class="col-md-6 form-group">
          <label>الاسم  العائلي بالعربية :</label>
          <input class="form-control" type="text" [value]="eleve.arnom" >
      </div>
      <div class="col-md-6 form-group">
          <label>الاسم  الشخصي بالعربية :</label>
          <input  class="form-control"   type="text"  [value]="eleve.arprenom" >
      </div>

      <div class="col-md-6 form-group">
          <label>الإسم بالفرنسية :</label>
          <input class="form-control"    type="text"  [value]="eleve.nom" >
      </div>
      <div class="col-md-6 form-group">
          <label>النسب بالفرنسية :</label>
          <input class="form-control" type="text" [value]="eleve.prenom" >
      </div>
      <div class="col-md-6 form-group">
            <label>تاريخ الازدياد :</label>
            <input class="form-control" type="text" [value]="eleve.dnaissance"  >
      </div>

      <div class="col-md-6 mt-25">
        <label for="myselect">الجنس : </label>
        <select class="form-control"  id='types'  >
          <option value="ذكر" >ذكر</option>
          <option value="انثى" >انثى</option>
        </select>
      </div>
      <div class="col-md-6 form-group">
        <label>المستوى :</label>
        <label class="form-control bggray" type="text"  >{{eleve.niveau}}</label>
    </div>
    <div class="col-md-6 form-group">
          <label>القسم :</label>
          <label class="form-control bggray" type="text"  >{{ eleve.cla }}</label>
    </div>
  </div>
  <div class="mybtn">
    <button  class="btn-primary ml-1" > الحفظ </button>
    <button  class="btn-primary" > المصادقة </button>
  </div>
  </div>
</div>
