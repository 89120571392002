<h4>لائحة تلاميذ المؤسسة </h4>
<app-choixclasses [choixSelect]="1" (save)="showfilterclass($event)"></app-choixclasses>

<!-- ICI TABS-->
<div [hidden]="showtable">
  <div class="card">
    <div class="card-body" >
<app-mytab [myarray]="arraytab"  (indexTab)="indexTab($event)" ></app-mytab>

<app-smarttable [data]="dataeleves"
[newKeysnames]="itemsSmartTableLink"
[maxview]="20"></app-smarttable>
    </div>
  </div>
</div>
