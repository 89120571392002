
<div *ngIf="etatshowit"  style="position:absolute;top:0; left:0;z-index: 1000; width: 100%;height:3000px;opacity: 0.5;background-color: lightgray;">
<div class="spinner-border text-secondary" style="position: relative; margin-top:20%; margin-right:50%;width: 3rem; height: 3rem;" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
<button id="modalbutton" type="button"
data-toggle="modal" data-target="#exampleModal" style="display: none;"></button>
<app-mymodal [info]="info"></app-mymodal>

