
          <div class="row">
              <div class="col-md-6 form-group">
                      <label>رمز التلميذ :</label>
                          <label class="form-control">{{eleve.nmassar}}</label>
                  <div class="form-group w-100">
                      <label>وضعية التلميذ : </label>
                          <label class="form-control">{{eleve.situation}}</label>
                  </div>

              </div>
              <div class="col-md-6 ">
                  <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-4">
                      <img class="img-circle"  [src]="eleve.image">
                  </div>
                  <div class="col-md-4 ">
                    <input style="display: none" type="file"
                    (change)="onFileChanged($event)"#fileInput>
                      <div class="myimg" (click)="fileInput.click()" > تحميل الصورة </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="row">
              <div class="col-md-6 form-group">
                  <label>الاسم  العائلي بالعربية :</label>
                  <input class="form-control" type="text" [(ngModel)]="eleve.arnom" >
              </div>
              <div class="col-md-6 form-group">
                  <label>الاسم  الشخصي بالعربية :</label>
                  <input  class="form-control"   type="text" [(ngModel)]="eleve.arprenom"  >
              </div>

              <div class="col-md-6 form-group">
                  <label>الإسم بالفرنسية :</label>
                  <input class="form-control"    type="text" [(ngModel)]="eleve.nom" >
              </div>
              <div class="col-md-6 form-group">
                  <label>النسب بالفرنسية :</label>
                  <input class="form-control" type="text" [(ngModel)]="eleve.prenom" >
              </div>
              <div class="col-md-6 form-group">
                    <label>تاريخ الازدياد :</label>
                    <input class="form-control" type="text" [(ngModel)]="eleve.dnaissance" >
              </div>

              <div class="col-md-6 mt-25">
                    <label class="radio control-label">النوع :</label>
                    <input type="radio" name='g' [(ngModel)]="eleve.genre" value="ذكر"> ذكر
                    <input type="radio" name='g'  [(ngModel)]="eleve.genre" value="أنثى"> انثى
              </div>
          </div>
          <div class="row">
              <div class="col-md-6 form-group">
                      <label>مكان الازدياد بالعربية :</label>
                          <textarea [(ngModel)]="eleve.arlieunaissance" class="form-control">{{eleve.arlieunaissance}}</textarea>
              </div>
              <div class="col-md-6 form-group">
                      <label>مكان الازدياد  بالفرنسية :</label>
                      <textarea [(ngModel)]="eleve.lieunaissance" class="form-control">{{eleve.lieunaissance}}</textarea>
              </div>

              <div class="col-md-6 form-group">
                      <label>العنوان :</label>
                      <textarea class="form-control"> </textarea>
              </div>
              <div class="col-md-6 mt-25">
                      <label class="radio control-label">الجنسية :</label>
                      <input type="radio" name='n' [(ngModel)]="eleve.nationalite" value="M">مغربية
                      <input type="radio" name='n' [(ngModel)]="eleve.nationalite" value="A"> اخرى
              </div>
              <div class="col-md-6 form-group">
                    <label>ر.ب.ت.و :</label>
                    <input type="text" class="form-control">
              </div>
              <div class="col-md-6 form-group">
                <label>نوع الاعاقة :</label>
                 <select class="form-control" [(ngModel)]="eleve.typeandicape">
                 <option></option>
                 <option value="إعاقة حركية">إعاقة حركية</option>
                 <option value="إعاقة ذهنية">إعاقة ذهنية</option>
                 <option value="الصم والبكم"> الصم والبكم</option>
                 <option value="المكفوفون">المكفوفون</option>
                 <option value="التوحد">التوحد</option>
                 <option value="اضطرابات التعلم">اضطرابات التعلم</option>
                 </select>
             </div>

<div class="col-md-6  form-group">
   <label>جاء من :</label>
<select class="form-control" [(ngModel)]="eleve.arrivede">
<option value=''></option>
<option value="التعليم الاولي العصري">التعليم الاولي العصري</option>
<option value="التعليم الخاص">التعليم الخاص</option>
<option value="التعليم الغير النظامي">التعليم الغير النظامي</option>
<option value="دولة أخرى">دولة أخرى</option>
<option value="باستعطاف">باستعطاف</option>
<option value="التعليم الاولي التقليدي">التعليم الاولي التقليدي</option>
</select>
</div>
<div class="col-md-6 form-group">
    <label>البلد الاصلي :</label>
    <select class="form-control" [(ngModel)]="eleve.pays">
    <option></option>
    <option *ngFor="let o of countries" >{{o}}</option>
    </select>
</div>

  <div class="col-md-12">
   <button  class="btn btn-primary" (click)="save()" > حفظ <i class="fa fa-save"></i></button>
  <button   class="btn btn-primary"> طبع البيانات<i class="fa fa-print"></i></button>
</div>


