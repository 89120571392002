<div class="card">
  <div class="card-body" >
    <div class="card-header"></div>
    <div style="padding: 10px">
<div class="row">
  <div class="col-6">
      <label>تاريخ المغادرة:</label>
      <app-mydatepicker [data]="ddepart" [myclass]="'form-control'" (dateChange)="ddepart = $event"></app-mydatepicker>
  </div>
  <div class="col-6">
      <label>تاريخ الالتحاق بالمؤسسة:</label>
      <app-mydatepicker [data]="dreception" [myclass]="'form-control'" (dateChange)="dreception = $event"></app-mydatepicker>
  </div>
<div style="margin:10px;"></div>
  <div class="col-12">
    <button  class="btn btn-primary" (click)="save()" > حفظ شهادة المغادرة <i class="fa fa-save"></i></button>
   <button   class="btn btn-primary" (click) ="cancel()" [disabled]="departs.length == 0"> الغاء<i class="fa fa-times"></i></button>
 </div>
</div>
<div class="clearfix mb-3"></div>
<app-smarttable [data]="departs" [newKeysnames]="{
  ascolaire: 'السنة الدراسية',
  ecoleorigine: 'رمز و اسم المؤسسة',
  ddepart: 'تاريخ المغادرة',
  ecoledestination: 'رمز و اسم مؤسسة الاستقبال',
  dreception : 'تاريخ الاستقبال'
}"></app-smarttable>
  </div>
</div>
</div>
