<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{'ok': info.etat == 'ok','err': info.etat == 'err', 'no': info.etat == 'no'}">
        <h5 class="modal-title" id="exampleModalLabel">
         <span *ngIf="info.etat == 'ok'">معلومات</span>
         <span *ngIf="info.etat == 'err'">خطأ</span>
         <span *ngIf="info.etat == 'no'">انذار</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <span *ngIf="info.etat == 'ok'" >
        <span *ngIf="info.message == ''">لقد تم اجراء التغييرات بنجاح</span>
        <span *ngIf="info.message != ''">{{info.message}}</span>
        </span>
       <span *ngIf="info.etat == 'err' && info.message == ''" >لا يمكن اجراء التغيير على الاقسام التالية : {{info.message}}  نضراً لوجود روابط بينها وبين وحدات أخرى</span>
       <span *ngIf="info.etat == 'err' && info.message != ''"  [innerHTML]="info.message"></span>

       <span *ngIf="info.etat == 'no'" [innerHTML]="info.message" ></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
         data-dismiss="modal" [ngClass]="{
            'ok': info.etat == 'ok',
            'err': info.etat == 'err',
            'no' : info.etat == 'no'
        }"
         >اغلاق</button>
      </div>
    </div>
  </div>
</div>
