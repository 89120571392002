<div class="card">
  <div class="card-body">
نوع الخطأ :  تلاميذ محولون و لم يتم استقبالهم <br>
المؤسسة : المؤسسة التعليمية
  </div>
</div>

<div class="card">
  <div class="card-body">
<app-smarttable
    [data]="dataeleves" [maxview]="20"
    [newKeysnames]="itemsSmartTableLink">
</app-smarttable>
  </div>
</div>
